import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import { FeatureBlockRepeaterEntity } from '../types/contentfulModels/FeatureBlockRepeaterEntity';
import { BuildCategoryEntity } from '../types/contentfulModels/BuildCategoryEntity';

import CalloutBlock from '../components/CalloutBlock/CalloutBlock';
import CarbonCallout from '../components/CarbonCallout/CarbonCallout';
import Hero from '../components/Hero/Hero';
import Offset from '../components/Offset/Offset';
import ProjectCallout from '../components/ProjectCallout/ProjectCallout';
import TrustBlock from '../components/TrustBlock/TrustBlock';
import BuildCategories from '../components/BuildCategories/BuildCategories';
import IconNewBuild from '../components/Icons/IconNewBuild';
import IconExtension from '../components/Icons/IconExtension';
import IconRenovation from '../components/Icons/IconRenovation';
import IconConversion from '../components/Icons/IconConversion';
import IconCommercial from '../components/Icons/IconCommercial';
import Seo from '../components/Seo/Seo';

interface BuildPageProps {
    data: {
        contentfulGenericPage: {
            title: string,
            heroImage?: {
                url: string
            }
            pageBody: Array<FeatureBlockRepeaterEntity|BuildCategoryEntity>
        }
    }
}

const BuildPage = ({ data }: BuildPageProps): JSX.Element => {
    const { title: pageTitle } = data.contentfulGenericPage;
    const heroImage = (data.contentfulGenericPage.heroImage) ? `${data.contentfulGenericPage.heroImage.url}?fm=webp&w=1920` : undefined;
    const buildCategoriesData = (data.contentfulGenericPage.pageBody[0] as BuildCategoryEntity).buildCategories;
    const trustBlockFeatures = (data.contentfulGenericPage.pageBody[1] as FeatureBlockRepeaterEntity).featureBlocks;

    return (
        <Fragment>
            <Seo title={pageTitle} />
            <div className="space-y-7">
                <Hero
                    height="small"
                    background={heroImage}
                >
                    <Hero.Body containerWidth="medium" padding="small">
                        <div className="space-y-1 max-w-0.25">
                            <Hero.Title><span className="gold">Building to higher standards</span></Hero.Title>
                            { /* <Hero.Subtitle>Nesciunt est dicta sit. Aperiam similique dolores et quas ut. Placeat neque explicabo. Tenetur at nesciunt.</Hero.Subtitle> */ }
                            { /* <LinkButton href="/project-planner" label="Start your project" icon={<IconArrowRight size="24px" stroke="slate-900" />} /> */ }
                        </div>
                    </Hero.Body>
                    <Offset position="offset-bottom" background="slate-900">
                        <Hero.Footer containerWidth="medium" className="pb-1">
                            <TrustBlock features={trustBlockFeatures} />
                        </Hero.Footer>
                    </Offset>
                </Hero>
                <section className="space-y-7 contain containMedium">
                    <header className="space-y-5">
                        <div className="space-y-1">
                            <h2 className="text-subtitle max-w-0.5">Fully comprehensive building services</h2>
                            <p className="text-paragraph max-w-prose">From concept to completion we have broad experience across all types of projects and genuinely thrive on technicality.</p>
                            <p className="text-paragraph max-w-prose">We are a team of qualified and very experienced professionals that actively seek out a challange.</p>
                            <p className="text-paragraph max-w-prose">Our vision is in changing the face of the local construction sector, and in giving our customers the best possible customer experience whilst delivering outstanding results.</p>
                        </div>
                        <ul className="blank-list flex gap-4 flex-wrap">
                            <li className="flex items-center gap-2">
                                <IconNewBuild size="32px" />
                                <span className="text-subheading">New builds</span>
                            </li>
                            <li className="flex items-center gap-2">
                                <IconExtension size="32px" />
                                <span className="text-subheading">Extensions</span>
                            </li>
                            <li className="flex items-center gap-2">
                                <IconRenovation size="32px" />
                                <span className="text-subheading">Renovations</span>
                            </li>
                            <li className="flex items-center gap-2">
                                <IconConversion size="32px" />
                                <span className="text-subheading">Conversions</span>
                            </li>
                            <li className="flex items-center gap-2">
                                <IconCommercial size="32px" />
                                <span className="text-subheading">Commercial work</span>
                            </li>
                        </ul>
                    </header>
                    <BuildCategories>
                        {
                            buildCategoriesData.map((category, index: number) => {
                                if (index === 2) {
                                    return (
                                        <Fragment>
                                            <CalloutBlock
                                                heading="Looking for design services?"
                                                body=""
                                                linkTo="/design"
                                                linkLabel="See what we can do"
                                            />
                                            <BuildCategories.Item
                                                heading={category.title}
                                                body={category.description.description}
                                                images={category.images?.images}
                                            />
                                        </Fragment>
                                    );
                                }

                                return (
                                    <BuildCategories.Item
                                        key={category.title}
                                        heading={category.title}
                                        body={category.description.description}
                                        images={category.images?.images}
                                    />
                                );
                            })
                        }

                    </BuildCategories>
                </section>
            </div>
            <ProjectCallout />
            <CarbonCallout />
        </Fragment>
    );
};

export const pageQuery = graphql`
query BuildPageContentQuery {
    contentfulGenericPage(slug: {eq: "buildPage"}) {
        title
        heroImage {
            url
        }
        pageBody {
          ... on ContentfulBuildCategoryRepeater {
            id
            buildCategories {
              title
              description {
                description
              }
              images {
                images {
                  gatsbyImageData(
                    width: 1920
                )
                }
                layout
              }
            }
          }
          ... on ContentfulTrustBlock {
            title
            featureBlocks {
              heading
              slug
              icon {
                name
                contentfulid
              }
              description {
                description
              }
            }
          }
        }
      }
  }
`;

export default BuildPage;
