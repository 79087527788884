import React from 'react';

import * as styles from './CalloutBlock.module.scss';

import LinkButton from '../Buttons/LinkButton/LinkButton';

interface CalloutBlockProps {
    heading: string,
    body: string,
    linkTo: string,
    linkLabel: string
}

const CalloutBlock = ({
    heading, body, linkTo, linkLabel
}: CalloutBlockProps): JSX.Element => (
    <div className={styles.CalloutBlock}>
        <div className="space-y-1">
            <h2 className="text-heading gold">{heading}</h2>
            <p className="text-metadata">{body}</p>
        </div>
        <LinkButton href={linkTo} label={linkLabel} />
    </div>
);

export default CalloutBlock;
