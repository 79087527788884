import React from 'react';

import * as styles from './Offset.module.scss';

const BackgroundTypes = {
    'slate-900': 'before:bg-slate-900',
};

const PositionTypes = {
    'offset-bottom': styles.OffsetBottom
};

interface OffsetProps {
    position: keyof typeof PositionTypes,
    background: keyof typeof BackgroundTypes,
    children: React.ReactNode
}


const Offset = ({ position, background, children }: OffsetProps): JSX.Element => (
    <div className={`${styles.Offset} ${PositionTypes[position]} ${BackgroundTypes[background]}`}>
        {children}
    </div>
);

export default Offset;
