import React from 'react';

import * as styles from './BuildCategories.module.scss';

import ImageGrid from '../ImageGrid/ImageGrid';
import LinkButton from '../Buttons/LinkButton/LinkButton';

import { ImageTileProps } from '../ImageTile/ImageTile';

interface BuildCategoriesStepProps {
    heading: string,
    body: string,
    images?: ImageTileProps['imageData'][]
}

const BuildCategoriesStep = ({
    heading, body, images
}: BuildCategoriesStepProps): JSX.Element => (
    <li className={styles.BuildCategoriesItem}>
        <div className={styles.BuildCategoriesItemContent}>
            <div className="space-y-3">
                <h3 className="text-subtitle">{heading}</h3>
                <p className="text-paragraph">{body}</p>
                <LinkButton href="/projects" label="View projects" bordered />
            </div>
        </div>
        {
            (images)
                ? (
                    <div className={styles.BuildCategoriesItemAsset}>
                        <ImageGrid
                            layout="oneOverTwo"
                            images={images}
                        />
                    </div>
                )
                : null
        }
    </li>
);

export default BuildCategoriesStep;
