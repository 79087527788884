import React from 'react';

import BuildCategoriesItem from './BuildCategoriesItem';

import * as styles from './BuildCategories.module.scss';

interface BuildCategoriesProps {
    children: React.ReactNode
}

const BuildCategories = ({ children }: BuildCategoriesProps): JSX.Element => (
    <ol className={styles.BuildCategories}>
        {children}
    </ol>
);

BuildCategories.Item = BuildCategoriesItem;

export default BuildCategories;
